import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@graphql(gql`mutation deleteFormMessage($deleteFormMessageMutation: DeleteFormMessageInput!) {
  deleteFormMessage(input: $deleteFormMessageMutation) {
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['MessageFormsEntityQuery', 'MessageFormsOrganizationQuery', 'MessageNodeEntityQuery', 'MessageNodeOrganizationQuery']
  }
  })
@inject('store') @observer
export default class FormResultDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteFormMessageMutation: {
        id: this.props.form.formMessageId
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Form was deleted'), success: true };
    this.props.store.appends.pop();
  })

  render() {
    return (
      <Controller
        id="FormResultDelete"
        delete
        object={__('form')}
        onSubmit={this.onSubmit}
        onCancel={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}
