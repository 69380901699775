import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader, Popup, Icon, Dropdown } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';

import Form from '../../components/ui/Form';
import Calendar from '../../components/Calendar';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import BetaButton from '../../components/ui/Button';
import BetaModal from '../../components/ui/Modal';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;
const date = new Date();

const styles = {
  checkbox: {
    margin: '24px 0px 24px 0px',
    display: 'inline-block',
    justifyContent: 'space-between',
  },
  checkboxMobile: {
    justifyContent: 'space-between',
  }
};

@inject('store', 'client')
@graphql(gql`query FormResultFormQuery($entityId: ID!, $limit: Int, $offset: Int) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      forms (limit: $limit, offset: $offset) {
        nodes {
          id: dbId
          name
          description
          fields {
            totalCount
          }
        }
        totalCount
      }
    }
  }
}`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
    entityId: ownProps.entity_id,
    limit: PAGE_SIZE,
    offset: 0
    }
    })
  })
@observer
export default class FormResultForm extends Form {
  defaultValues = {
    name: '',
    id: null,
    description: null,
    hasDateLimit: true,
    dateLimit: new Date(date.getTime() + (30 * 86400000)),
  }

  rules = {
    id: 'required'
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client, entity_id } = nextProps;

    if ((data.loading && !data.node) || !data.node) return;

    const { nodes, totalCount } = data.node.forms;

    if (totalCount === nodes.length) return;

    try {
      const response = await client.query({
        query: gql`
          query FormResultFormQuery($entityId: ID!, $limit: Int) {
            node (id: $entityId) {
              ... on Entity {
                id: dbId
                forms (limit: $limit) {
                  nodes {
                    id: dbId
                    name
                    description
                    fields {
                      totalCount
                    }
                  }
                }
              }
            }
          }`,
        variables: {
          entityId: entity_id,
          limit: totalCount
        }
      });


      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.node.forms.nodes = response.data.node.forms.nodes;

        return nextResult;
      });
    } catch (e) {
      console.log(e);
    }
  }

  onSelectForm = (e, { value }) => {
    const { forms, data, store } = this.props;

    if (forms.find(form => form.id === value)) {
      store.snackbar = { active: true, message: __('You can\'t send a form twice in the same message'), success: false, dismissAfter: 4000 };
      return;
    }

    const { id, name, description } = data.node.forms.nodes.find(form => form.id === value);
    this.setState({ values: { ...this.state.values, id, name, description } });
  }

  renderOptions = () => {
    const { values, errors } = this.state;
    const { data } = this.props;

    const { nodes } = data.node.forms;

    return (
      <div>
        <Form.Dropdown
          label={__('Form')}
          className={'form-dropdown formresult'}
          placeholder={nodes && nodes.length > 0 && __('Select a form')}
          disabled={nodes && nodes.length === 0}
          selection
          icon="angle down"
          text={nodes && nodes.length === 0 && __('No forms added. Request for a form registration to your institution administrator')}
          value={values.id}
          options={nodes.map(form => ({ text: form.name, value: form.id, key: form.id, 'data-id': form.id }))}
          onChange={this.onSelectForm}
        />
        {
          errors && errors.id &&
            <div className="error-warning-red" >
              <Icon data-value="error" name="exclamation circle" />
              {errors.id}
            </div>
        }
      </div>
    );
  }

  render() {
    const { data, i } = this.props;

    const actionButtonsLeft = [<BetaButton
      data-action="cancel"
      round
      transparent
      floated="left"
      text={__('Cancel')}
      onClick={() => this.props.store.appends.pop()}
    />];
    const actionButtonsRight = [<BetaButton
      data-action="submit"
      round
      icon={{ name: 'check' }}
      text={i === null ? __('Add') : __('Save')}
      onClick={this.handleSubmit}
    />];

    if ((data.loading && !data.node) || !data.node) {
      return (
        <Form id="FormResultForm">
          <Loader active centered />
        </Form>
      );
    }

    const { values } = this.state;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return (
      <Form
        id="FormResultForm"
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        paddingHeader={this.isMobile()}
        header={this.isMobile() ? {
          title: '',
          onClose: () => this.props.store.appends.pop(),
          invertCloseButton: true,
          headerItem: <BetaButton
            data-action="submit"
            round
            text={i === null ? __('Add') : __('Save')}
            onClick={this.handleSubmit}
            style={{ height: '40px', width: '100px', justifyContent: 'center', display: 'flex' }}
          /> } : null}
        onSubmit={this.handleSubmit}
        style={this.isMobile() ? { paddingLeft: '24px', paddingRight: '24px' } : {}}
        {...this.props}
      >
        {this.renderOptions()}
        <div style={this.isMobile() ? { ...styles.checkboxMobile, margin: '24px 0px 24px 0px' } : styles.checkbox}>
          <Form.Field
            className="last-item"
          >
            <ColoredCheckbox
              name="hasDateLimit"
              label={__('Set a filling deadline')}
              checked={values.hasDateLimit}
              onClick={() => {
                this.setValue('dateLimit', new Date(date.getTime() + (30 * 86400000)));
                this.onInputChange(null, { name: 'hasDateLimit', checked: !values.hasDateLimit });
              }}
              toggle
              disabled={this.props.edit}
              radio
              radioChecked
              style={{ fontSize: '16px', display: 'flex' }}
              bold={false}
              color="#084FFF"
            />
          </Form.Field>
        </div>
        {
          values.hasDateLimit &&
            (
              this.isMobile() ?
                <Form.Group style={{ alignItems: 'flex-end', margin: '0px 0px 26px 0px' }}>
                  <div
                    onClick={() => this.props.store.appends.push(<BetaModal
                      toast
                      id={'FormDateLimitToast'}
                      header={__('Deadline')}
                      closeOnRootNodeClick
                      invertCloseButton
                      scrolling
                      onClose={() => this.props.store.appends.pop()}
                      content={
                        <Calendar
                          withPadding
                          calendarStyles
                          selectedDt={values.dateLimit}
                          onSelect={(d) => { this.setValue('dateLimit', d.selectedDt); this.props.store.appends.pop(); }}
                          maxDate={values.date}
                        />
                      }
                    />)}
                    style={this.isMobile() ? { width: '100%' } : {}}
                  >
                    <Form.Input
                      label={__('Filling deadline')}
                      labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                      readOnly
                      icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                      value={utils.simpleDate(values.dateLimit, true, 'll', lang)}
                    />
                  </div>
                </Form.Group>
                :
                <Form.Group style={{ alignItems: 'flex-start', flexDirection: 'column', margin: '0px 16px 26px 0px' }}>
                  <div style={{ marginBottom: '12px', display: 'flex' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{__('Filling deadline')}</span>
                    <Popup
                      trigger={<Icon name="info circle" style={{ color: '#a0a0a0', marginTop: '-2px', marginLeft: '10px', fontSize: '20px', fontWeight: '300' }} />}
                      content={__('When you ask the recipients to fill a form, the limit is until 11:59 pm on the chosen day.')}
                      style={{ textAlign: 'justify' }}
                      basic
                    />
                  </div>
                  <Popup
                    trigger={(
                      <div>
                        <Form.Input
                          className="calendar-input"
                          value={utils.simpleDate(values.dateLimit, true, 'll', lang)}
                          icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                          readOnly
                        />
                      </div>
                    )}
                    content={<Calendar
                      calendarStyles
                      selectedDt={values.dateLimit}
                      onSelect={d => this.setValue('dateLimit', d.selectedDt)}
                      maxDate={values.date}
                    />}
                    position="bottom left"
                    hideOnScroll
                    on="click"
                  />
                </Form.Group>
            )
        }
      </Form>
    );
  }
}
