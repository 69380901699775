import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Table } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Form from '../../components/Form';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import Modal from '../../components/Modal';
import Responsive from '../../components/Responsive';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(gql`query FormResultFillQuery($formId: ID!) {
  node (id: $formId) {
    ... on Form {
      id: dbId
      name
      fields(limit: 400, orderBy: {column: POSITION}) {
        nodes {
          id: dbId
          name
          type
          options
          initial
        }
      }
    }
  }
}`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
    formId: ownProps.form.dbId
    }
    })
  })
@graphql(gql`mutation createFormResult($createFormResultMutation: CreateFormResultInput!) {
    createFormResult(input: $createFormResultMutation) {
      clientMutationId
    }
  }`, {
  options: {
  refetchQueries: ['MessageFormsEntityQuery', 'MessageFormsOrganizationQuery']
  }
  })
@observer
export default class FormResultFill extends Responsive {
  state = {
    fields: [],
    step: 'fill'
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.fields.length && nextProps.data.node) {
      const { fields } = nextProps.data.node;

      const parsedFields = fields.nodes.map((field) => {
        if (field.type === 'SELECT') {
          field.result = field.options[parseInt(field.initial, 10)];
        } else if (field.type === 'CHECK') {
          const parsed_initial = field.initial && field.initial.length ? field.initial.map(o => parseInt(o, 10)) : null;
          field.result = field.options.map((value, i) => (
            {
              value,
              selected: !!parsed_initial && parsed_initial.indexOf(i) > -1
            }
          ));
        } else if (field.type === 'TEXT') {
          field.result = field.initial[0] || '';
        }
        return field;
      });

      this.setState({ fields: parsedFields });
    }
  }

  onCancel = () => {
    this.props.store.appends.push(<Modal
      id="FormResultFillCloseModal"
      size="tiny"
      onClose={() => this.props.store.appends.pop()}
      header={__('Discard form answers')}
      content={__('Are you sure you want to discard the answers filled in this form?')}
      actions={[
        <Button
          data-action="cancel"
          key={0}
          basic
          floated="left"
          content={__('Cancel')}
          onClick={() => this.props.store.appends.pop()}
        />,
        <Button
          data-action="submit"
          key={2}
          negative
          content={__('Discard')}
          onClick={() => {
            this.props.store.appends.pop();
            this.props.store.appends.pop();
          }}
        />
      ]}
    />);
  }

  onSubmit = () => {
    this.setState({ loading: true });

    const { params, form, mutate, store } = this.props;
    const { fields } = this.state;

    const results = [];

    fields.forEach((field) => {
      const f = { ...field };
      if (field.type === 'CHECK' && field.result !== null) {
        f.result = field.result.filter(item => item.selected).map(item => item.value);
        f.result = (f.result.length > 0) ? JSON.stringify(f.result) : null;
      }

      if (!f.result) return;

      results.push({
        value: f.result,
        formFieldId: field.id
      });
    });

    return mutate({
      variables: {
        createFormResultMutation: {
          formMessageId: form.formMessageId,
          entityId: (typeof params.entity_id === 'string' ? parseInt(params.entity_id, 10) : params.entity_id),
          results
        }
      }
    }).then(async () => {
      store.snackbar = { active: true, message: __('Form was succesfully filled'), success: true };
      store.appends.pop();
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
      this.setState({ loading: false });
    });
  }

  setField(i, value) {
    const { fields } = this.state;
    fields[i].result = value;
    this.setState({ fields });
  }

  handleCancel = () => {
    const { step } = this.state;

    if (step === 'fill') {
      this.onCancel();
    } else {
      this.setState({ step: 'fill' });
    }
  }

  handleSubmit = () => {
    const { step } = this.state;

    if (step === 'fill') {
      this.setState({ step: 'confirm' });
    } else {
      this.onSubmit();
    }
  }

  isEverythingAnswered = () => {
    const { fields } = this.state;
    return !fields.filter((field) => {
      if (field.type === 'CHECK') {
        return !field.result.filter(option => option.selected).length;
      } else if (field.type === 'SELECT') {
        return !field.result;
      }

      return !(field.result && field.result.trim().length > 0);
    }).length;
  }

  renderFieldName = field => <p className="bold wordWrapped" style={{ padding: '16px 0px', margin: '0px', borderBottom: '1px solid #eeeeee' }}>{field.name}</p>

  renderCheck = (field, i) => (
    <Form.Field data-name="checkbox" data-value={i} key={i} style={{ margin: '0px' }}>
      {this.renderFieldName(field)}
      <div style={{ padding: '16px 0px' }}>
        {
          field.result.map((option, j) =>
            <div key={j} style={{ wordBreak: 'break-all' }}>
              <ColoredCheckbox
                data-value={j}
                key={j}
                label={option.value}
                checked={option.selected}
                onClick={() => {
                  field.result[j].selected = !field.result[j].selected;
                  this.setField(i, field.result);
                }}
              />
            </div>)
        }
      </div>
    </Form.Field>
  );

  renderSelect = (field, i) => {
    const options = field.options.map((option, k) => ({ text: option, value: option, 'data-value': k }));

    return (
      <div key={i}>
        {this.renderFieldName(field)}
        <div style={{ padding: '16px 0px' }}>
          <Form.Dropdown
            data-action="open-select-option"
            data-params={i}
            key={i}
            selection
            search
            noResultsMessage={__('No results were found')}
            options={options}
            value={field.result}
            onChange={(e, data) => this.setField(i, data.value)}
          />
        </div>
      </div>
    );
  };

  renderText = (field, i) => (
    <div key={i}>
      {this.renderFieldName(field)}
      <div style={{ padding: '16px 0px' }}>
        <Form.TextArea
          data-name="textarea"
          data-value={i}
          key={i}
          rows="5"
          value={field.result}
          onChange={(e, data) => this.setField(i, data.value)}
        />
      </div>
    </div>
  );

  renderFields = () =>
    this.state.fields &&
    this.state.fields.map((field, i) => {
      if (field.type === 'CHECK') {
        return this.renderCheck(field, i);
      } else if (field.type === 'SELECT') {
        return this.renderSelect(field, i);
      }
      return this.renderText(field, i);
    })

  renderFieldsView = () => (<div>
    {this.renderFields()}
  </div>)

  renderPreview = (field) => {
    if (['TEXT', 'SELECT'].includes(field.type)) {
      return field.result;
    }

    return field.result.filter(r => r.selected).map(r => r.value).join(', ');
  }

  renderFieldsConfirmation = () =>
    this.state.fields &&
    this.state.fields.map((field, i) => (
      <Table.Row
        key={i}
        style={{ backgroundColor: i % 2 === 0 ? null : '#f6f7f8' }}
      >
        <Table.Cell textAlign={this.isMobile() ? 'left' : 'right'} style={{ width: '50%', border: 'none', fontWeight: 'bold' }}>{field.name}</Table.Cell>
        <Table.Cell textAlign="left" style={{ whiteSpace: 'pre-line', border: 'none' }}>{this.renderPreview(field)}</Table.Cell>
      </Table.Row>
    ))

  renderConfirmation = () => (<div>
    <p>{__('Please check your answers before submiting')}</p>
    <Table basic="very">
      <Table.Body>
        {this.renderFieldsConfirmation()}
      </Table.Body>
    </Table>
  </div>)

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { name } = data.node;
    const { loading, step } = this.state;
    const formProps = {
      modal: true,
      onSubmit: this.handleSubmit,
      onCancel: this.handleCancel,
      submitButton: {
        disabled: !this.isEverythingAnswered() || loading
      },
      loading
    };

    if (step === 'fill') {
      formProps.submitButton.content = __('Next');
    } else {
      formProps.cancelButton = __('Back');
    }

    return (<Modal
      id={'formResultFill'}
      size="small"
      closeOnRootNodeClick={false}
    >
      <Modal.Header className="wordWrapped">{name}</Modal.Header>
      <Modal.Content style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
        <Form id="FormResultFill" {...formProps}>
          {
            step === 'fill' ?
              this.renderFieldsView()
              :
              this.renderConfirmation()
          }
        </Form>
      </Modal.Content>
      <Modal.Actions />
    </Modal>
    );
  }
}
