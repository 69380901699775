import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Card, Container, Icon, Button, Dropdown } from 'semantic-ui-react';
import gql from 'graphql-tag';

import FormResultMultiple from './Multiple';
import FormResultFill from './Fill';
import FormResultDelete from './Delete';
import Responsive from '../../components/Responsive';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  button: {
    backgroundColor: 'white',
    color: '#212529',
    borderRadius: '24px',
    padding: '15px 0px',
    border: '1px solid #e9ecef',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: 8
  },
};

@inject('store') @observer
export default class FormResultItem extends Responsive {
  static fragments = {
    // if you need the id add to the component using the fragment
    // querying here can cause conflicts cause we need dbId in some components and key in others
    form: gql`
      fragment FormResultItem on Form {
        dbId
        formMessageId
        name
        description
        dateLimit
        expired
        deleted
        entity {
          id: dbId
          fullname
        }
        entities (limit: 1) {
          nodes {
            id: dbId
          }
          totalCount
        }
        results(queryAll: true, limit: 500) {
          nodes {
            formFieldId
            entityId
            name
            type
            value
            created
          }
        }
      }`,
  }

  renderFormFields = results => results?.map((result, i) =>
    <Table.Row
      className="formResultItem"
      title={result.name}
      data-id={result.formFieldId}
      data-testid='formResultItemResult'
      key={i}
      style={{ backgroundColor: i % 2 === 0 ? null : '#f6f7f8' }}
    >
      <Table.Cell textAlign={this.isMobile() ? 'left' : 'right'} style={{ width: '50%', border: 'none', fontWeight: 'bold' }}>{result.name}</Table.Cell>
      <Table.Cell textAlign="left" style={{ whiteSpace: 'pre-line', border: 'none' }}>{result.value.join(', ')}</Table.Cell>
    </Table.Row>
  )

  renderDate = (date, format = 'LL, HH:mm') => {
    const dateString = new Date(date);
    const dateAdjusted = utils.adjustTZDate(dateString, -dateString.getTimezoneOffset());

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    if(this.props.messageToApprove) {
      return utils.simpleDate(dateString, true, format, lang);
    }

    return utils.simpleDate(dateAdjusted, true, format, lang);
  }

  render() {
    const { form, store, params, messageType, fromHistory, messageToApprove } = this.props;
    const { entity, id, formMessageId, name, results, entities, description, dateLimit, expired, deleted, hasDateLimit } = form;
    const resultPreview = results?.nodes.filter(result => result.entityId === entity?.id);
    const messageId = params.message_id;
    const owner = messageType === 'SENT' || params.organization_id;
    const canFill = !expired && (params.entity_id == store.currentEntity.id || store.currentEntity.type === 'ADMIN');

    const entitiesCount = form?.entities?.totalCount;
    const resultsLength = form?.results?.nodes.length;
    const limitRows = entitiesCount * resultsLength;
    const lang = store.app.locale ? (store.app.locale === 'pt-BR' ? 'pt' : store.app.locale) : 'en';

    let items = [];

    const exportLink = `${store.app.url}/csv/form/results?` +
      `limit=${limitRows}&message_id=${messageId}&form_message_id=${formMessageId}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${lang}`;

    if (owner && !fromHistory) {
      items = items.concat(
        <Dropdown.Item as="a" href={exportLink}>
          {__('Export')}
        </Dropdown.Item>
      );
    }

    if (owner && !deleted && (!params.organization_id || store.currentEntity.type === 'ADMIN') && !fromHistory) {
      items = items.concat([<Dropdown.Item
        onClick={() => this.props.store.appends.push(<FormResultDelete form={form} params={params} />)}
      >
        {__('Delete')}
      </Dropdown.Item>
      ]);
    }
    
    return (
      <Card
        className="formItem"
        title={name}
        data-id={id}
        fluid
        style={{ marginBottom: '1em' }}
        key={id}
      >
        <Card.Content>
          <Container textAlign="center">
            <div className="horizontallySpacedItems">
              <div className="bold" style={{ maxWidth: '80%' }}><Icon name="poll h" style={{ color: '#0080ff', margin: '0 .25rem 0 0', padding: 0 }} />{name}</div>
              {
                items.length ?
                  <Dropdown icon={null} trigger={<Icon data-action="open-form-options" name="ellipsis v" />}>
                    <Dropdown.Menu className="bold" style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
                      {items}
                    </Dropdown.Menu>
                  </Dropdown>
                  :
                  null
              }
            </div>
            {!!description && <div style={{ padding: '8px 0px', display: 'flex' }}>{description}</div>}
            {
              messageType === 'SENT' || messageToApprove || params.organization_id || (resultPreview.length && !deleted) ?
                <Table basic="very">
                  <Table.Body>
                    {
                      (messageType === 'SENT' || messageToApprove || params.organization_id) && entity && !!entities.totalCount &&
                      <Table.Row key={'details'}>
                        <Table.Cell textAlign="left" style={{ borderBottom: '#eeeeee 1px solid' }}>
                          <div>{__('You are viewing')} <span className="bold" style={{ color: '#0080ff' }}> {__("%s's Form", entity.fullname)}</span></div>
                        </Table.Cell>
                        <Table.Cell textAlign="right" style={{ borderBottom: '#eeeeee 1px solid' }}>
                          <div
                            className="bold pointer"
                            style={{ color: '#0080ff', fontSize: '12px' }}
                            onClick={() => store.appends.push(<FormResultMultiple form={form} params={params} />)}
                          >
                            {__('View all forms').toUpperCase()} {`(${entities.totalCount})`}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    }
                    {
                      (messageType === 'SENT' || messageToApprove || params.organization_id) && !(entity && entities.totalCount) &&
                      <Table.Row key={'empty'}>
                        <Table.Cell textAlign="center">
                          <div style={{ color: '#a0a0a0' }}>{__('Your form has no answers yet')}</div>
                        </Table.Cell>
                      </Table.Row>
                    }
                    {this.renderFormFields(resultPreview)}
                  </Table.Body>
                </Table>
                :
                !deleted && <Button
                  fluid
                  style={styles.button}
                  onClick={() => store.appends.push(<FormResultFill form={form} params={params} />)}
                  disabled={!canFill}
                >
                  {__('Fill the form')}
                </Button>
            }
            {
              messageType === 'RECEIVED' && !!resultPreview?.length && !deleted &&
              <p style={{ color: '#a0a0a0', paddingTop: '8px' }}>
                {__('Filled in %s', this.renderDate(resultPreview[0]?.created, 'LL'))}
              </p>
            }
            {
              deleted &&
                <p style={{ color: '#f04040' }}>
                  <Icon name="times" />
                  {__('Form deleted in %s', this.renderDate(deleted, 'LL'))}
                </p>
            }
            {
              ((!messageToApprove && dateLimit) || hasDateLimit) && !deleted &&
              <p data-testid='limitDate' style={{ color: expired ? '#a0a0a0' : '#ffb43c', paddingTop: '8px' }}>
                <Icon name="exclamation circle" />
                {
                  expired ?
                    __('Expired %s', this.renderDate(dateLimit))
                    :
                    __('Expires %s', this.renderDate(dateLimit))
                }
              </p>
            }
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
